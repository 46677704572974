<template>
  <div >
    <wfSidebar></wfSidebar>
  </div>
  
</template>

<script>
import wfSidebar from '@/components/wfSidebar.vue';
export default {
    components:{
        wfSidebar
    }
}
</script>

<style>

</style>