<template>
  <div id="homeContainer">
    <Header></Header>
      
      <!-- 地图和总概括 -->
      <div class="viewport">
        <div class="column">
          <!-- 住宅所在区域比例图 -->
          <BarEcharts v-if="ishow" :homePositionValue="homePositionValue"></BarEcharts>
          <!-- 住宅年代图 -->
          <div class="panel">
            <div class="inner">
              <h3>建造年代</h3>
              <div class="homeChart">
                <dv-active-ring-chart ref="chart" :config="ageConfig"
                class="dv-active-ring-chart-class" style="" />
              </div>
              
            </div>
          </div>
        <!-- 指标与测点数量 -->
        <BarEcharts v-if="ishow" :homePositionValue="sensorVal"></BarEcharts>
        </div>
        <div class="column">
          <div class="map">
            <Mapl></Mapl>
          </div>
        </div>
        <div class="column">
          <BarEcharts v-if="ishow" :homePositionValue="installVal"></BarEcharts>
          <div class="panel">
              <div class="inner">
              <h3>设备在线状态</h3>
              <div class="WfOnline">
                <div v-for="item in WfName" :key="item.id">
                {{ item.name }} 
                <el-tooltip :content="'Switch value: ' + item.status" placement="top">
                  <el-switch
                    v-model="item.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="1"
                    inactive-value="0">
                  </el-switch>
                </el-tooltip>
              </div>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="inner">
              <h3>电子化归档</h3>
              <div class="homeChart" style="padding-top:8px">
                <dv-water-level-pond  :config="dimensionconfig" class="homeChartWidth" />
              </div>
              
            </div>
          </div>
          
          <!-- <BarEcharts v-if="ishow" :homePositionValue="dimensionalVal"></BarEcharts> -->
        </div>
      </div>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Mapl from '@/components/Map.vue'
import BarEcharts from '@/components/barEcharts.vue'

import { HubConnectionBuilder } from '@microsoft/signalr';

export default {
  name: 'HomeView',
  components: {
    Header,
    Mapl,
    BarEcharts
  },
  data() {
    return {
      webSocketUrl:"http://114.132.77.194:8081/ChartHub",
      //webSocketUrl:"https://localhost:7248/ChartHub",
      webSocket: null,
      serverTimer:null,
      WfName:[
      ] ,
      ageConfig:{
        radius: '75%',
        activeRadius: '80%',
        data:[
        {
          name:"明",
          value:1
        },{
          name:"民",
          value:4
        },{
          name:"清",
          value:2
        }
      ]
      },
      onLineConfig:{
        radius: '70%',
        activeRadius: '75%',
        data:[
        {
          name:"在线率",
          value:75
        },
        {
          name:"离线率",
          value:25
        }
      ],
      digitalFlopStyle: {
        fontSize: 25,
        fill: '#fff'
      }
      },
      homePositionValue: {
        type:'pie',
        name:'地理位置',
        data:[]
      },
      sensorVal:{
        type:'bar',
        name:'监测指标与数量',
        data:[]
      },
      installVal:{
        type:'pie',
        name:'监测系统实施比例',
        data:[]
      },
      dimensionalVal:{
        type:'npie',
        name:'三维模型电子化归档',
        data:[]
      },
      // 控制组件有没有传值
      ishow:false,
      dimensionconfig:{
        data:[75],
        colors:['#37A5D6','#0046B6'],
        // colors:['red','green'],
        shape:'round',
        waveNum:1,
        waveHeight:25,
        waveOpacity:0.5,
      },
      webSocket:null,
      socket :null
    }
  },
  mounted() {
    
    window.addEventListener('load', function() {

       const screenWidth = window.innerWidth;
       const screenHeight = window.innerHeight;
       console.log('当前屏幕宽度:', screenWidth);
       console.log('当前屏幕高度:', screenHeight);
       if (screenWidth === 2560 && screenHeight === 1440) {
         console.log('当前屏幕分辨率符合媒体查询中的条件');
       } else {
         console.log('当前屏幕分辨率不符合媒体查询中的条件');
       }
     });
    this.getInfo()
    this.getOnlineStatus()
    window.addEventListener('beforeunload', () => {
      if (this.webSocket) {
      this.webSocket.invoke('CloseTimer',this.serverTimer).catch(function(err){console.error(err.toString())})
      this.webSocket.stop();
      this.webSocket = null;
    }
    });
  },
  methods: {
    getInfo() {
    // 指标与测点数量
      var option2 = {
        // color: new $echarts.graphic.LinearGradient(
        //     //(x1, y1)点到点(x2, y2)之间进行渐变
        //     0, 0, 0, 1, [
        //         { offset: 0, color: '#00fffb' }, // 0 起始颜色
        //         { offset: 1, color: '#0061ce' }, // 1 结束颜色
        //     ]
        // ),
        tooltip: {
            trigger: 'item'
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '4%',
            top: '7%',
            // 图表位置紧贴画布边缘是否显示刻度以及label文字 防止坐标轴标签溢出跟grid区域有关系
            containLabel: true,
            // 是否显示直角坐标系网格
            show: true,
            // grid 四条边框的颜色
            borderColor: 'rgba(0, 240, 255, 0.3)'
        },
        // 控制x轴
        xAxis: [{
            // 使用类目，必须有data属性
            type: 'category',
            // 使用data中的数据设为刻度文字
            data: [],
            // 刻度设置
            axisTick: {
                // true 意思：图形和刻度居中中间
                //false 意思：图形在刻度之间
                alignWithLabel: false,
                // 不显示刻度
                show: false,
            },
            axisLabel: {
                color: "#4c9bfd",
                fontSize: 12,
                formatter: function(value) {
                  return value.slice(0,2) + '...'
                }
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(0, 240, 255, 0.3)'
                        // width:8, x轴线的粗细
                        // opcity：0，如果不想显示x轴线 则改为0
                }
            }
        }],
        // 控制y轴
        yAxis: [{
            type: 'value',
            axisTick: {
                show: false
            },
            axisLabel: {
                color: "#4c9bfd"
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(0, 240, 255, 0.3)'
                }
            },
            // 控制y轴分割线的颜色样式
            splitLine: {
                lineStyle: {
                    color: 'rgba(0, 240, 255, 0.3)'
                }
            }
        }],
        series: [{
            name: '数据指标与总量',
            type: 'bar',
            barWidth: '60%',
            data: []
        }]
     }
      this.$http.get(`Home/GetHomeInfo`).then(res => {
        if(res.status === 200) {
          this.homePositionValue.data = res.data.region
          this.sensorVal.data = res.data.num
          this.installVal.data = res.data.installation
          this.dimensionalVal.data = res.data.dimensional
          const newAgeData = res.data.age.map(item => ({name:item.age,value:item.num}))
          this.UpdateChartData(newAgeData)
          // this.onLineConfig.data[0].value = res.data.data.line[0]
          // this.onLineConfig.data[1].value = res.data.data.line[1]
          // this.onLineConfig = {...this.onLineConfig}
         // console.log('newAgeData');
          //console.log(newAgeData);
          //console.log('this.ageConfig');
         // console.log(this.ageConfig);
          this.ishow= true
          //console.log(option2);

        }else{
          alert('连接错误,请稍后再试')
        }
      })
    },
    UpdateChartData(newAgeData) {
      this.ageConfig.data = newAgeData
      this.ageConfig = { ...this.ageConfig }
    },
    
    async getOnlineStatus(){
      if (this.webSocket) {
        await this.webSocket.invoke('CloseTimer',this.serverTimer).catch(function(err){console.error(err.toString())})
        this.webSocket.stop();
        this.webSocket = null;
      }else{
        if (!this.webSocket) {
        //this.webSocket = new WebSocketWrapper(url);
         this.webSocket = new HubConnectionBuilder().withUrl(this.webSocketUrl,{withCredentials:false}).build()
        }
        try {
        this.webSocket.start().then(()=>{
        this.webSocket.invoke('OnlineStatus').catch(function(err){console.error(err.toString())})
      }).catch(err=>console.log(err))
        const response = await new Promise((resolve) => {
          // 接收一次消息后结束
          this.webSocket.on('ReceiveOnlinestatusMessage',ReceiveData=>{
            //console.log("resDataresDataresDataresDataresData",ReceiveData)
            this.serverTimer = ReceiveData.timerId
            this.WfName=ReceiveData['t_Onlinestatus']
            resolve(ReceiveData);
            })
        });
      } catch (error) {
        console.error(error);
      }
      
      }
    }


  },
  beforeDestroy(){
      this.webSocket.invoke('CloseTimer',this.serverTimer).catch(function(err){console.error(err.toString())})
      this.webSocket.stop();
      this.webSocket = null;
  }
  
}
</script>

<style lang="scss" >

@media screen and (min-width: 2560px)  {
    #homeContainer {
      font-family: Arial, Helvetica, sans-serif;
      margin: 0;
      padding: 15px;
      height: 100vh;
      background-image: url("../assets/bg.jpg");
      background-size: cover;
      color: #d3d6dd;
      // min-height: 780px;
      height: 100%;
      font-family:"xkfont";
    }
    .dv-active-ring-chart-class{
      width:12rem;height:12rem;
    }
    .viewport {
        display: flex;
        
    }

    .viewport .column {
        flex: 2.5;
        // display: flex;
        // flex-direction: column;
        /* background-color: pink; */
    }
    .viewport .column .panel{
      height: 21.6rem;
      // flex: 1;
      margin-bottom: 0.8rem;
    }

    .viewport .column:nth-child(2) {
        flex: 5;
        margin: 0 .8rem 0;
    }

    .panel {
        position: relative;
        border: 15px solid red;
        border-width: 3rem 2.7rem 2.6rem 4rem;
        border-image-source: url(../assets/border.png);
        border-image-slice: 51 38 20 132;
        margin-bottom: .25rem;
    }
    .WfOnline{
      margin-top: 1.5rem;
      display: flex;
      justify-content:flex-end;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem; /* 设置子项之间的间隔 */
    }
    .WfOnline > * {
      flex: 0 0 calc(50% - 0.5rem);
    }

    .inner {
        position: absolute;
        top: -3.5rem;
        left: -4rem;
        right: -2.6rem;
        bottom: -2.5rem;
        padding: 1.4rem 1rem;
    }

    .panel h3 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .lineChart,
    .homePositionChart{
      width: 100%;
      height: 100%;
    }
    .homePositionChart .barChart{
      width: 100%;
      height: 100%;
    }

    .homeChart{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .map{
      width: 100%;
      height: 95%;
    }
    .beian{
      width: 100%;
      text-align: center;
      display: flex;
        justify-content: center;
    }
    .beian a{
      font-family:"楷体", "KaiTi", serif;
      color:#ccc;
      margin-right:10px;
    }
    .homeChartWidth{
      width: 12rem;
      height: 12rem;
    }

}
@media screen and (min-width: 1920px) and (max-width:2559px)   {
    #homeContainer {
      font-family: Arial, Helvetica, sans-serif;
      margin: 0;
      padding: 15px;
      height: 100vh;
      background-image: url("../assets/bg.jpg");
      background-size: cover;
      color: #d3d6dd;
       //min-height: 780px;
      height: 100%;
      font-family:"xkfont";
    }
    .dv-active-ring-chart-class{
      width:9rem;height:9rem;
    }
    .viewport {
        display: flex;
        
    }

    .viewport .column {
        flex: 2.5;
        // display: flex;
        // flex-direction: column;
        /* background-color: pink; */
    }
    .viewport .column .panel{
      height: 15.4rem;
      // flex: 1;
      margin-bottom: 0.6rem;
    }

    .viewport .column:nth-child(2) {
        flex: 5;
        margin: 0 .8rem 0;
    }

    .panel {
        position: relative;
        border: 15px solid red;
        border-width: 3rem 2.7rem 2.6rem 4rem;
        border-image-source: url(../assets/border.png);
        border-image-slice: 51 38 20 132;
        margin-bottom: .25rem;
    }
    .WfOnline{
      margin-top: 1.5rem;
      display: flex;
      justify-content:flex-end;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem; /* 设置子项之间的间隔 */
    }
    .WfOnline > * {
      flex: 0 0 calc(50% - 0.5rem);
    }

    .inner {
        position: absolute;
        top: -3.5rem;
        left: -4rem;
        right: -2.6rem;
        bottom: -2.5rem;
        padding: 1.4rem 1rem;
    }

    .panel h3 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .lineChart,
    .homePositionChart{
      width: 100%;
      height: 100%;
    }
    .homePositionChart .barChart{
      width: 100%;
      height: 100%;
    }

    .homeChart{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .map{
      width: 100%;
      height: 95%;
    }
    .beian{
      width: 100%;
      text-align: center;
      display: flex;
        justify-content: center;
    }
    .beian a{
      font-family:"楷体", "KaiTi", serif;
      color:#ccc;
      margin-right:10px;
    }
    .homeChartWidth{
      width: 10rem;
      height: 10rem;
    }

}
@media screen and (max-width: 1919px)   {
    #homeContainer {
      font-family: Arial, Helvetica, sans-serif;
      margin: 0;
      padding: 15px;
      height: 100vh;
      background-image: url("../assets/bg.jpg");
      background-size: cover;
      color: #d3d6dd;
      // min-height: 780px;
      height: 100%;
      font-family:"xkfont";
    }
    .dv-active-ring-chart-class{
      width:9rem;height:9rem;
    }
    .viewport {
        display: flex;
        
    }

    .viewport .column {
        flex: 2.5;
        // display: flex;
        // flex-direction: column;
        /* background-color: pink; */
    }
    .viewport .column .panel{
      height: 12.5rem;
      // flex: 1;
      margin-bottom: 0.5rem;
    }

    .viewport .column:nth-child(2) {
        flex: 5;
        margin: 0 .8rem 0;
    }

    .panel {
        position: relative;
        border: 15px solid red;
        border-width: 3rem 2.7rem 2.6rem 4rem;
        border-image-source: url(../assets/border.png);
        border-image-slice: 51 38 20 132;
        margin-bottom: .25rem;
    }
    .WfOnline{
      margin-top: 1.5rem;
      display: flex;
      justify-content:flex-end;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem; /* 设置子项之间的间隔 */
    }
    .WfOnline > * {
      flex: 0 0 calc(50% - 0.5rem);
    }

    .inner {
        position: absolute;
        top: -3.5rem;
        left: -4rem;
        right: -2.6rem;
        bottom: -2.5rem;
        padding: 1.4rem 1rem;
    }

    .panel h3 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .lineChart,
    .homePositionChart{
      width: 100%;
      height: 100%;
    }
    .homePositionChart .barChart{
      width: 100%;
      height: 100%;
    }

    .homeChart{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .map{
      width: 100%;
      height: 95%;
    }
    .beian{
      width: 100%;
      text-align: center;
      display: flex;
        justify-content: center;
    }
    .beian a{
      font-family:"楷体", "KaiTi", serif;
      color:#ccc;
      margin-right:10px;
    }
    .homeChartWidth{
      width: 8rem;
      height: 8rem;
    }

}
</style>
