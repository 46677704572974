<template>
    <div class="HeaderClass">
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else class="host-body" style="margin-bottom: 1.5rem;">
            <div class="d-flex jc-center">
            <dv-decoration-10 style="width:18%; height: .1rem;" />
            <div class="d-flex jc-center">
                <dv-decoration-8 :color="['#568aea', '#000000']" style="width:18rem;height:2.625rem;" />
                <div class="title">
                <span class="title-text">文物建筑健康监测与活化利用平台</span>
                <dv-decoration-6
                    class="title-bototm"
                    :reverse="true"
                    :color="['#50e3c2', '#67a1e5']"
                    style="width:25rem;height:.6rem;"
                />
                </div>
                <dv-decoration-8
                :reverse="true"
                :color="['#568aea', '#000000']"
                style="width:18rem;height:2.625rem;"
                />
            </div>
               <dv-decoration-10 style="width:18%;height:.1rem; transform: rotateY(180deg);" />
            </div>

            <!-- 第二行 -->
            <div class="d-flex jc-between px-2" style="float: right">
            
            <div style="width: 100%" class="d-flex">
                
                <div
                class="react-left mr-4"
                style="width: 20rem; text-align: right;"
                >
                <span class="react-after"></span>
                <span class="text">{{ newDate }}</span>
                </div>
            </div>
            </div>
        
        </div>
    </div>
</template>
<script>
import { timeWeekFormat } from '../utils'
export default {
   data() {
      return {
        loading: false,
        // 当前时间
        newDate:''
      }
   },
   created(){
   },
   mounted(){
    this.refreshTime()
   },
   computed:{
   },
   methods:{
    refreshTime() {
      setInterval(() => {
        this.getNowTime()
      }, 500)
    },
    getNowTime() {
      let times = new Date()
      this.newDate = timeWeekFormat(times)
    }
   },
}
</script>
<style lang="scss">
@import "../assets/scss/index.scss";

@media screen and (min-height: 900px) and (min-width: 1920px) and (max-width:2559px) and (max-height:1199px) {
  .react-left mr-4 {
    
  }
}


.host-body{
  margin-top: 0px;
}
.dv-border-box-8{
  padding: 2px;
  
}
.host-body .title .title-bototm{
  bottom: -0.6rem;
}
.title-text{
  font-family: 'title';
  font-size: 2.2rem;
}
</style>
