<template>
  <div >
      <!-- Sidebar -->
      <div class="adminx-sidebar expand-hover push"  style="background-color: #4F4F4F;">
        <ul class="sidebar-nav">
          <li class="sidebar-nav-item">
            <a class="sidebar-nav-link active">
              <span class="sidebar-nav-icon">
                <i class="el-icon-s-custom"></i>
              </span>
              <span class="sidebar-nav-name">
                {{ UserName }}
              </span>
              <span class="sidebar-nav-end">

              </span>
            </a>
          </li>

          <li class="sidebar-nav-item">
            <a href="#" class="sidebar-nav-link"  @click="navigateToLogin('HomeViewWf')"> 
              <span class="sidebar-nav-icon">
                <i class="el-icon-data-analysis"></i>
              </span>
              <span class="sidebar-nav-name">
                主页
              </span>
              <!--
              <span class="sidebar-nav-end">
                <span class="badge badge-info">4</span>
              </span>-->
            </a>
          </li>

          <li class="sidebar-nav-item" v-if="superbool">
            <a class="sidebar-nav-link collapsed" data-toggle="collapse" href="#" @click="navigateToLogin('SuperPage')"   aria-expanded="false" aria-controls="example">
              <span class="sidebar-nav-icon">
                <i class="el-icon-mobile"></i>
              </span>
              <span class="sidebar-nav-name">
                管理页面
              </span>
              <span class="sidebar-nav-end">
                <i data-feather="chevron-right" class="nav-collapse-icon"></i>
              </span>
            </a>
          </li>
        </ul>
      </div><!-- Sidebar End -->

      <!-- adminx-content-aside -->
        <div class="adminx-content">
          <div class="adminx-main-content">
          <div class="container-fluid">
            <component :is="activeComponent"></component>
          </div>
        </div>
        
      </div>
  </div>
      
      
</template>

<script>
import vendor from "../assets/cssAndjs/vendor.js"
import HomeViewWf from "@/views/HomeViewWf.vue";
import wfSuperPage from "./wfSuperPage.vue";
export default {
    data(){
      return{
        activeComponent: HomeViewWf,
        UserName:"",
        superbool:false
      }
    },
    components:{
      HomeViewWf
    },
    created(){

      if(sessionStorage.getItem("isLoggedIn")=="true"){
        this.UserName = sessionStorage.getItem("username")
        if(sessionStorage.getItem('lv')==1){
          this.superbool=true
        }else{
          this.superbool=false
        }
      }else{
        this.$router.push("/login");
      }
       
    },
    methods:{
      navigateToLogin(Page) {
        if(Page=='HomeViewWf'){
          this.activeComponent = HomeViewWf;
        }else if(Page=='SuperPage'){
          this.activeComponent = wfSuperPage;
        }
        
        
      },
    }
}
</script>

<style scoped>
@import url("../assets/cssAndjs/adminx.css");

body {
  position: relative;
}
.adminx-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.adminx-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

</style>