<template>
    <div>
        <div  id="container"></div>
        <el-dialog title="提示" ref="input" :visible.sync="dialogVisible" width="20%" :modal="false"
              :before-close="handleClose">
              <p  style="text-align: center;font-size: 25px;">{{ yanzhengHomeName }}</p>
              <p style="text-align: center;font-size: 25px;">请输入验证码</p>
              <el-input ref="input2" v-model="input" @keyup.enter="submitInput" placeholder="请输入验证码" show-password></el-input>
              <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="primary" @click="submitInput">确 定</el-button>
              </span>
          </el-dialog>
    </div>
    
</template>

<script>
const loadMapScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://webapi.amap.com/maps?v=2.0&key=e8979b3eb74aa74c5b939d2a28384b7c';
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};
import icon from '../assets/img/icon2.png'
export default {
    data() {
        return {
            yanzhengHomeName:'',
            dialogVisible: false,
            input: '',
            homeInfo: '',
            // 标记点数组
            markers: [],
            windows: [],
            window: '',
            options: [
                {
                    id: 2,
                    position:[
                        118.184575,24.520388
                    ],
                    label: {
                        content: '王清祥',
                    }
                },
                {
                    id: 5,
                    position:[
                        118.067122,24.444411
                    ],
                    label: {
                        content: '三一堂',
                    }
                },
                {
                    id: 8,
                    position:[
                        118.1931069,24.5191073
                    ],
                    label: {
                        content: '林鸿儒',
                    }
                },
                {
                    id: 9,
                    position:[
                        118.1839872,24.5198639
                    ],
                    label: {
                        content: '石大春',
                    }
                }

            ],
            map:"",
            changeButtonName:"修改"
        }
    },
    created() {
        document.onkeyup = e => {
            if (e.keyCode === 13 && this.dialogVisible === true) {
                this.submitInput()
                
            }
        }

    },
    mounted() {
        loadMapScript().then(() => {
            this.initMap();
        }).catch(error => {
            console.error('加载高德地图脚本失败：', error);
        });
    },
    computed: {
    },
    methods: {
        initMap() {
            const map = new AMap.Map('container', {
                center: [118.172452, 24.510275],
                zoom: 13,
            });
        
            this.markers = []; // 存储标记点的数组

            // 遍历 markerData 创建标记点并添加到地图和 markers 数组
            this.options.forEach(data => {
                const marker = new AMap.Marker({
                position: data.position,
                map: map,
                icon: new AMap.Icon({
                    image: icon,
                    size: new AMap.Size(32, 32),
                    anchor: new AMap.Pixel(16, 32),
                }),
                label: {
                    content: data.label.content,
                    position: "BM",
                    minZoom: 15,
                },
                });
                marker.on('click', () => {
                    this.input=""
                    this.dialogVisible = true
                    this.homeInfo={id:data.id,label:{content:  data.label.content},lat:data.position[1],lng:data.position[0]}
                });
                this.markers.push(marker);
            });
            this.map=map
        },
        viewDetail(e) {
            this.yanzhengHomeName = e.target.De.label.content
            console.log(e.target.De.label.content)
            console.log(e.target.De.position.lat)
            console.log(e.target.De.position.lng)
            this.pointMarker.forEach((item, index) => {
                this.dialogVisible = true

                if (item.lat.toFixed(6) == e.target.De.position.lat && item.lng.toFixed(6) == e.target.De.position.lng) {
                    this.homeInfo = item
                    // this.$router.push({
                    //     path: '/detail',
                    //     // query: {
                    //     //     homeInfo: item
                    //     // }
                    // })
                    // this.dialogVisible = false
                    localStorage.setItem('homeInfo', JSON.stringify(item))
                    console.log('this.input', this.$refs.input.$children);
                    this.$nextTick(() => {
                        // this.$refs.input.$children[2].$el.focus()
                        this.$refs.input2.$el.querySelector('input').focus()
                        console.log('this.inputnext', this.$refs.input.$children[2]);
                        console.log('this.input2', this.$refs.input2.$el.querySelector('input'));
                    });
                }
            })
        },
        // 关闭弹出框
        handleClose(done) {
            this.dialogVisible = false
            this.input = ''
        },
        // 提交用户输入的验证码
        submitInput() {
            if(this.homeInfo.id===9){
            this.$message({
                    message: '本住宅暂未开放',
                    type: 'warning'
                });
            this.dialogVisible = false
            this.input = ''
            return
        }
        localStorage.setItem('homeInfo', JSON.stringify(this.homeInfo))
        this.$http.get(`Home/GetHousePwd?houseId=${this.homeInfo.id}&housePwd=${this.input}`).then(res => {
            console.log('res', res);
            if (res.data) {
                this.$router.push({
                    path: '/detail',
                })
            } else {
                this.$message({
                    message: '验证码输入错误，请重新输入',
                    type: 'warning'
                });

            }
            this.dialogVisible = false
            this.input = ''
            console.log(res);
            console.log('res');
        }).catch((error) => {
            if(error.response.data.status == 500) {
                this.$message({
                    message: '验证码不能为空',
                    type: 'info'
                });
                console.log('error',error);
            }
        })
        console.log(this.input);
        console.log('this.input');

        }
    }
}
</script>
<style lang="scss" scoped>

.Container {
    width: 100%;
    height: 99%;
}

.Container .amap-marker-label {
    color: #fcaf17;
}

.el-dialog h3 {
    margin-bottom: 1rem;
    text-align: center;
}
</style>
